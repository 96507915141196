body {
  margin: 0;
  height: 100vh;
  width: 100vw;
}

.projectImage {
  width: 300px;
  height: 300px;
  position: relative;
}

.commingSoon {
  text-align: center;
}

.rickRoll {
  left: 50vw;
  position: absolute;
  transform: translateX(-50%);
}

.divider {
  height: 50px;
}

.red {
  color: red;
}

.github-text-large {
  font: 800 44px "Segoe UI", Ubuntu, Sans-Serif;
}

.github-text-small {
  font: 600 28px "Segoe UI", Ubuntu, Sans-Serif;
}
